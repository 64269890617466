@import '../../../../styles/theme.less';

.employee {
  height: 100%;
  background-color: @white-color;;

  &__sider {
    background-color: white;
    padding-top: 18px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__content {
    overflow: auto;

    &-cards {
      max-width: 900px;

      section {
        margin: 48px 24px 24px;
        font-weight: 600;
        color: @primary-color;

        &#basicinfo {
          margin-top: 0;
        }
      }

      .hires {
        .ant-badge-count {
          color: #096dd9;
          background-color: #e6f7ff;
          box-shadow: 0 0 0 1px #e6f7ff inset;
        }

        button {
          svg {
            stroke: @greyish-color;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  &__employee {
    display: flex;
    margin: 10px 10px 18px;

    &-avatar {
      &-info {
        display: flex;
      }

      &-uploader {
        display: block;
        width: 48px;
        text-align: center;

        a.link,
        .ant-spin {
          font-size: 11px;
        }
      }
    }

    &-info {
      flex: 1;
      margin-left: 10px;
      overflow: hidden;

      > h1 {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      > span.ant-typography {
        display: block;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &-pencil-icon {
      margin-top: -10px;
      margin-left: 38px;
    }
  }

  &__audit {
    text-align: right;
    padding-right: 24px;
    padding-bottom: 24px;
  }

  &__confirm-delete {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

@primary-color: #3caef2;@brand-color: #edf8ff;@secondary-color: #ff8500;@darkish-color: #474747;@greyish-color: #616161;@grey-color: #aeaeae;@light-grey-color: #ddd;@light-color: #f5f5f5;@white-color: white;@success-color: #42a10a;@danger-color: #de4503;@warning-color: #ff8500;@link-color: @secondary-color;@primary-bg-color: #edf8ff;@secondary-bg-color: #fff7e6;@font-family: 'Mulish', 'SegoeUI', -apple-system;@label-color: @greyish-color;@text-color: @darkish-color;@border-color-base: #d3dade;@border-radius-base: 5px;@card-head-font-size: 14px;@checkbox-border-width: 2px;@descriptions-title-margin-bottom: 10px;@form-item-label-font-size: 13px;@form-item-margin-bottom: 18px;@input-border-color: #d3dade;@layout-header-background: #fff;@layout-body-background: #fff;@layout-sider-background: @brand-color;@layout-header-height: 43px;@modal-header-border-width: 0;@modal-footer-border-width: 0;@modal-footer-padding-vertical: 30px;@select-border-color: #d3dade;@alert-success-border-color: #afeea2;@alert-success-bg-color: #e7f6e4;@table-padding-vertical: 12px;@btn-font-size-sm: 12.5px;